<template>
  <div class="bigTitle">{{title}}</div>
</template>

<script>
export default {
  name: "TitleBar",
  props: {
    title: {
      type: String,
      default: "", //title名字
    },
  },
  data() {
    return {};
  },
};
</script>

<style lang="less" scoped>
.bigTitle{
    font-size: 18px;
    font-weight: 700;
    color: #000;
}
</style>