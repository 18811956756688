<template>
  <div class="bigBox">
    <div>
      <BigTitle title="订单信息"></BigTitle>
      <div class="typeDiv">
        <div class="smallBox">
          <div class="label">订单号：</div>
          <div class="info">{{ form.orderId }}</div>
        </div>
        <div class="smallBox">
          <div class="label">时间：</div>
          <div class="info">{{ form.time }}</div>
        </div>
        <div class="smallBox">
          <div class="label">购买量：</div>
          <div class="info">{{ form.count }}</div>
        </div>
        <div class="smallBox">
          <div class="label">开票金额：</div>
          <div class="info">￥{{ form.money }}</div>
        </div>
      </div>
    </div>
    <div>
      <BigTitle title="发票信息"></BigTitle>
      <div class="typeDiv">
        <div class="smallBox">
          <div class="label">名称：</div>
          <div class="info">{{ form.orderId }}</div>
        </div>
        <div class="smallBox">
          <div class="label">税号：</div>
          <div class="info">{{ form.time }}</div>
        </div>
        <div class="smallBox">
          <div class="label">开户行：</div>
          <div class="info">{{ form.count }}</div>
        </div>
        <div class="smallBox">
          <div class="label">银行账户：</div>
          <div class="info">{{ form.count }}</div>
        </div>
        <div class="smallBox">
          <div class="label">备注：</div>
          <div class="info">{{ form.content }}</div>
        </div>
      </div>
    </div>
    <div>
      <BigTitle title="地址信息"></BigTitle>
      <div class="typeDiv">
        <div class="smallBox">
          <div class="label">收件人：</div>
          <div class="info">{{ form.name }}</div>
        </div>
        <div class="smallBox">
          <div class="label">手机：</div>
          <div class="info">{{ form.phone }}</div>
        </div>
        <div class="smallBox">
          <div class="label">邮箱：</div>
          <div class="info">{{ form.count }}</div>
        </div>
        <div class="smallBox">
          <div class="label">收件地址：</div>
          <div class="info">{{ form.adree }}</div>
        </div>
      </div>
    </div>
    <div class="footer">
      <el-button @click="goBack" size="small" style="width:128px">返回</el-button>
      <el-button type="primary" size="small" style="magin: 0px 40px;width:128px" @click="showLogistics"
        >立即开票</el-button
      >
      <el-button type="warning" size="small">开票对接钉钉开票</el-button>
    </div>
    <el-dialog title="物流信息" :visible.sync="isLogistics" width="30%" center>
      <div class="dialogForm">
        <el-form :model="form" size="small">
          <el-form-item label="物流公司">
            <el-input
              v-model="form.gongsi"
              placeholder="请输入物流公司"
              style="width: 200px"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="物流单号">
            <el-input
              v-model="form.danhao"
              placeholder="请输入物流单号"
              style="width: 200px"
              clearable
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="isLogistics = false" size="small">取 消</el-button>
        <el-button type="primary" @click="sure" size="small">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import BigTitle from "../../components/bigTitle";
export default {
  components: {
    BigTitle,
  },
  data() {
    return {
      isLogistics: false,
      form: {},
    };
  },
  mounted() {
    this.getDetail();
  },
  methods: {
    getDetail() {},
    showLogistics() {
      this.isLogistics = true;
    },
    sure() {
      this.isLogistics = false;
    },
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="less" scoped>
.bigBox {
  padding: 20px;
  box-sizing: border-box;
  .typeDiv {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-bottom: 20px;
    margin-top: 10px;
    .smallBox {
      width: 33%;
      display: flex;
      justify-content: flex-start;
      margin-bottom: 10px;
      .label {
        width: 80px;
      }
      .info {
        flex: 1;
      }
    }
  }
  .footer {
    display: flex;
    justify-content: center;
  }
  .dialogForm{
    width: 300px;
    margin: 0 auto;
  }
}
</style>
